import React from "react"
import { Router as MyRouter } from "@reach/router"
import { StaticQuery, graphql } from "gatsby"
import { compose } from "ramda"
import { getEdgesForQuery, getQueryData, getSectionDataFor } from "@utils/utils"
/**
 * import Sections
 */
import MainLayout from "@layouts/MainLayout/MainLayout"
import HeaderSection from "@components/shared/HeaderSection/HeaderSection"
import ContactSection from "@components/shared/ContactSection/ContactSection"
import imageCareers from "@assets/pictures/buehne_jobs.svg"
import PositionDetail from "./PositionDetail/PositionDetail"

const PositionDetails = props => (
  <StaticQuery
    query={graphql`
      query positionDetailQueryAndPositionDetailQuery {
        content: allMarkdownRemark(
          filter: {
            frontmatter: {
              path: { eq: "/careers" }
              published: { eq: true }
              lang: { eq: "de" }
            }
          }
        ) {
          edges {
            node {
              frontmatter {
                path
                subtitle
                title
                section
                blocks {
                  label
                  text
                }
              }
              internal {
                content
              }
            }
          }
        }
        footer: allMarkdownRemark(
          filter: {
            frontmatter: {
              path: { eq: "/ui" }
              published: { eq: true }
              lang: { eq: "de" }
            }
          }
        ) {
          edges {
            node {
              frontmatter {
                section
                title
                cta
                ctaUrl
              }
              internal {
                content
              }
              html
            }
          }
        }
      }
    `}
    render={data => {
      const content = compose(getQueryData, getEdgesForQuery("content"))(data)
      const headerSectionData = getSectionDataFor(
        "careers-section-header",
        content
      )
      const footer = compose(getQueryData, getEdgesForQuery("footer"))(data)
      const contactSectionData = getSectionDataFor(
        "ui-section-footer-apply",
        footer
      )
      return (
        <header>
          <MainLayout>
            <HeaderSection data={headerSectionData} image={imageCareers} />
            <PositionDetail id={props.id}></PositionDetail>
            <ContactSection data={contactSectionData} />
          </MainLayout>
        </header>
        
      )
    }}
  />
)

const Router = () => {
  return (
    <MyRouter>
      <PositionDetails path="/position-details/:id"/>
    </MyRouter>
  )
}

export default Router
